export const skills = [
  "Java",
  "Golang",
  "Node",
  "C#",
  "AWS Lambda",
  "AWS Cognito",
  "Kafka",
  "SQL",
  "DynamoDB",
  "Couchbase",
  "Dockers",
  "Kubernetes",
];
