import { CodeBracketIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import {GetProjects, Project} from "../services/PortfolioApi";


interface ProjectsReponse {
  Id: string
  Version?: string
  projects?: Project[]
}

export default function Projects() {

  const [projects, setProjects] = useState<ProjectsReponse>({Id: ""});
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response: ProjectsReponse = await GetProjects("main") as ProjectsReponse;
        setProjects(response);
      } catch (err) {
        console.error(err);
      }
    }

    fetchProjects();
  }, []);

  if (!projects.projects) {
    console.log("still loading");
    return <p>Loading...</p>;
  }
  return (
    <section id="projects" className="text-gray-400 bg-gray-900 body-font">
      <div className="container px-5 py-10 mx-auto text-center lg:px-40">
        <div className="flex flex-col w-full mb-20">
          <CodeBracketIcon className="mx-auto inline-block w-10 mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
            Notable Projects
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Here's a few of the notable projects I feel I've had a large impact on.
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          {projects.projects.map((project) => (
            <a
              href={project.link}
              key={project.image}
              className="sm:w-1/2 w-100 p-4">
              <div className="relative h-full">
                <img
                  alt="gallery"
                  className="absolute inset-0 w-full h-full object-contain object-center"
                  src={project.image}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                  <h2 className="tracking-widest text-sm title-font font-medium text-green-400 mb-1">
                    {project.subtitle}
                  </h2>
                  <h1 className="title-font text-lg font-medium text-white mb-3">
                    {project.title}
                  </h1>
                  <p className="leading-relaxed">{project.description}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}
